import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from './components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from './enum/PrivilegesCategories';
import { AdministrationRoutes } from './features/administration';
import { TemplatesRoutes } from './features/dashboard-templates';
import { DashboardsRoutes } from './features/dashboards';
import { DomainsRoutes } from './features/domains';
import { ErrorsRoutes } from './features/errors';
import { EvRoutes } from './features/ev';
import { ExternalServicesRoutes } from './features/external-services';
import { GroupsRoutes } from './features/groups';
import { JobsRoutes } from './features/jobs';
import { OcpiRoutes } from './features/ocpi-credentials-int';
import { StationsRoutes } from './features/stations';
import { TokensRoutes } from './features/tokens';
import { UsersRoutes } from './features/users';

const ServicePackages = React.lazy(() => import('./pages/ServicePackages'));
const Manage = React.lazy(() => import('./pages/Manage'));
const Home = React.lazy(() => import('./pages/Home'));
const Map = React.lazy(() => import('./pages/Map'));

const UserSettings = React.lazy(
    () => import('./features/users/routes/UserSettings')
);
/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <Navigate replace to="/home" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/home',
        Component: <Home />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/administration/*',
        Component: <AdministrationRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: true,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/map',
        Component: <Map />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/service-packages',
        Component: <ServicePackages />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/manage/tokens/*',
        Component: <TokensRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.Customer_Token_Admin,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/manage/overview',
        Component: <Manage />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.Customer_Token_Admin,
            PrivilegesCategories.Customer_User_Admin,
            PrivilegesCategories.Customer_Group_Admin,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/manage/users/*',
        Component: <UsersRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.Customer_User_Admin,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/manage/domains/*',
        Component: <DomainsRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/manage/groups/*',
        Component: <GroupsRoutes />,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.Customer_Group_Admin,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/manage/ocpi/credentials/int/*',
        Component: <OcpiRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_ReadOnly,
            PrivilegesCategories.PWM_Development,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/manage/*',
        Component: <Navigate replace to="/manage/overview" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/dashboards/*',
        Component: <DashboardsRoutes />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard,
            PrivilegesCategories.Domain_Dashboard,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: true,
    },
    {
        path: '/errors/*',
        Component: <ErrorsRoutes />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard,
            PrivilegesCategories.Domain_Dashboard,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/dashboard-templates/*',
        Component: <TemplatesRoutes />,
        needsGroupPrivilege: [
            PrivilegesCategories.Group_Dashboard_Template,
            PrivilegesCategories.Domain_Dashboard_Template,
        ],
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/stations/*',
        Component: <StationsRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: true,
    },
    {
        path: '/user/settings',
        Component: <UserSettings />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/external-services/*',
        Component: <ExternalServicesRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_ReadOnly,
            PrivilegesCategories.PWM_Admin,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/jobs/*',
        Component: <JobsRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: PrivilegesCategories.Jobs,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/ev/*',
        Component: <EvRoutes />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_ReadOnly,
            PrivilegesCategories.PWM_Development,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/home" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: undefined,
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
const Pages = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default Pages;
