import React from 'react';

import { Navigate } from 'react-router-dom';

import ExtendedRoutes from '../../../components/ExtendedRoutes/ExtendedRoutes';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';
const OcpiIntCredentials = React.lazy(() => import('./OcpiCredentialsInt'));
const CreateOcpiIntCredential = React.lazy(
    () => import('./CreateOcpiCredentialInt')
);
const OcpiIntCredential = React.lazy(() => import('./OcpiCredentialInt'));
const EditOcpiIntCredential = React.lazy(
    () => import('./EditOcpiIntCredential')
);

/**
 * In diesem Array werden alle Seiten mit dem Pfad, der benötigten Berechtigung und ob diese im Slave aufgerufen werden können, definiert.
 */
const routes = [
    {
        path: '/',
        Component: <OcpiIntCredentials />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_ReadOnly,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/create',
        Component: <CreateOcpiIntCredential />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_ReadOnly,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:country_code/:party_id',
        Component: <OcpiIntCredential />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_ReadOnly,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/:country_code/:party_id/edit',
        Component: <EditOcpiIntCredential />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_ReadOnly,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
    {
        path: '/*',
        Component: <Navigate replace to="/" />,
        needsGroupPrivilege: undefined,
        needsDomainPrivilege: [
            PrivilegesCategories.PWM_Admin,
            PrivilegesCategories.PWM_Development,
            PrivilegesCategories.PWM_Service,
            PrivilegesCategories.PWM_ReadOnly,
        ],
        needsAdminPrivilege: undefined,
        needsObjectPrivilege: undefined,
        initialObjectContext: false,
        slaveAccessible: false,
    },
];

/**
 * Diese Komponente definiert alle Seiten und überprüft ob der Benutzer
 * ausreichend Rechte besitzt, um diese Seite aufzurufen.
 * @returns {JSX.Element}
 * @constructor
 */
export const OcpiRoutes = () => {
    return <ExtendedRoutes routes={routes} />;
};

export default OcpiRoutes;
